<template>
    <div>
        <div class="board__view">
            <div class="board__view__row">
                <v-row class="row--x-small" align-md="center" justify-md="space-between">
                    <v-col cols="12" md="">
                        <v-row class="row--x-small">
                            <v-col>
                                <p class="page-text font-weight-medium">
                                    {{ notification?.subject }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <div class="d-flex justify-end align-center">
                            <i class="icon icon-clock mr-4 mr-lg-8"></i>
                            <p class="page-text page-text--sm grey-9--text">
                                {{ $dayjs(notification?.createdAt).format("YYYY.MM.DD") }}
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="board__view__row">
                <board-count-view justifyEnd :viewCount="notification?.viewCount" />               
            </div>
            <div class="board__view__row board__contents">
                <div v-html="notification?.content" class="board__content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import BoardTit from "@/components/client/board/board-item__tit.vue";
import BoardCountView from "@/components/client/board/board-count--view.vue";

export default {
    components: {
        BoardTit,
        BoardCountView,
    },

    data() {
        return {
            notification: null
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { notification } = await api.v1.center.notifications.get({ _id: this._notification })
            this.notification = notification;
        },
    },
    computed: {
        _notification() {
            return this.$route.params._notification;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>