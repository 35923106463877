var render = function render(){
  var _vm$notification, _vm$notification2, _vm$notification3, _vm$notification4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board__view"
  }, [_c('div', {
    staticClass: "board__view__row"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align-md": "center",
      "justify-md": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', [_c('p', {
    staticClass: "page-text font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$notification = _vm.notification) === null || _vm$notification === void 0 ? void 0 : _vm$notification.subject) + " ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end align-center"
  }, [_c('i', {
    staticClass: "icon icon-clock mr-4 mr-lg-8"
  }), _c('p', {
    staticClass: "page-text page-text--sm grey-9--text"
  }, [_vm._v(" " + _vm._s(_vm.$dayjs((_vm$notification2 = _vm.notification) === null || _vm$notification2 === void 0 ? void 0 : _vm$notification2.createdAt).format("YYYY.MM.DD")) + " ")])])])], 1)], 1), _c('div', {
    staticClass: "board__view__row"
  }, [_c('board-count-view', {
    attrs: {
      "justifyEnd": "",
      "viewCount": (_vm$notification3 = _vm.notification) === null || _vm$notification3 === void 0 ? void 0 : _vm$notification3.viewCount
    }
  })], 1), _c('div', {
    staticClass: "board__view__row board__contents"
  }, [_c('div', {
    staticClass: "board__content",
    domProps: {
      "innerHTML": _vm._s((_vm$notification4 = _vm.notification) === null || _vm$notification4 === void 0 ? void 0 : _vm$notification4.content)
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }